import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Typography,
    Box,
    Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { setOpenLeadCaptureModal } from "../../features/home/homeSlice";
import { confirmLead } from "../../util/functions";
import Iconify from "../iconify";
import { theme } from "antd";

const LeadCapture = () => {
    const { openLeadCaptureModal } = useSelector(
        (state: RootState) => state.home
    );
    const dispatch = useDispatch();

    return (
        <Dialog
            open={openLeadCaptureModal.open}
            onClose={() => {
                dispatch(
                    setOpenLeadCaptureModal({
                        open: false,
                        type: "",
                        text: "",
                        id: "",
                    })
                );
            }}
            PaperProps={{
                style: {
                    display: "flex",
                    width: "375px",
                    padding: "24px 20px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    borderRadius: "24px",
                    background: "var(--Neutral-Colors-100, #FFF)",
                },
            }}
        >
            {openLeadCaptureModal.type === "leadConfirm" ? (
                <img
                    src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Get+in+touch.png"
                    alt="leadConfirm"
                    style={{
                        display: "flex",
                        width: "120px",
                        padding: "10px",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                />
            ) : (
                <Iconify
                    icon={"material-symbols:check-circle-rounded"}
                    width="80px"
                    height="80px"
                    sx={{
                        color: (theme) => theme?.palette?.success?.main,
                        display: "flex",
                        width: "120px",
                        height: "120px",
                        padding: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
            )}
            <Stack flexDirection={"column"} gap={"4px"}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        color: (theme) => theme?.palette?.text?.primary,
                    }}
                >
                    {openLeadCaptureModal?.type === "lead"
                        ? "Thanks for showing your interest"
                        : "Hey, our team will get in touch with you to discuss further"}
                </Typography>
                {openLeadCaptureModal?.type === "lead" && (
                    <Typography
                        variant="body3"
                        sx={{
                            textAlign: "center",
                            color: (theme) => theme?.palette?.text?.secondary,
                        }}
                    >
                        Our team will get in touch with you within 24 hrs
                    </Typography>
                )}
            </Stack>
            <Divider sx={{ width: "100%" }} variant="dashed" />
            {openLeadCaptureModal?.type === "lead" && (
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        dispatch(
                            setOpenLeadCaptureModal({
                                open: false,
                                type: "",
                                text: "",
                                id: "",
                            })
                        );
                    }}
                    size="small"
                    sx={{
                        background: (theme) => theme?.palette?.brand?.main,
                        color: "#FFF",
                    }}
                >
                    <Typography variant="overline" sx={{ color: "#FFF" }}>
                        Got it
                    </Typography>
                </Button>
            )}
            {openLeadCaptureModal?.type === "leadConfirm" && (
                <Stack flexDirection={"row"} width={"100%"} gap={"16px"}>
                    <Button
                        variant="text"
                        fullWidth
                        onClick={() => {
                            dispatch(
                                setOpenLeadCaptureModal({
                                    open: false,
                                    type: "",
                                    text: "",
                                    id: "",
                                })
                            );
                        }}
                        size="small"
                    >
                        <Typography
                            variant="overline"
                            sx={{ color: (theme) => theme?.palette.brand.main }}
                        >
                            No
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            confirmLead(openLeadCaptureModal.id);
                            dispatch(
                                setOpenLeadCaptureModal({
                                    open: false,
                                    type: "",
                                    text: "",
                                    id: "",
                                })
                            );
                        }}
                        fullWidth
                        size="small"
                        sx={{
                            background: (theme) => theme?.palette?.brand?.main,
                            color: "#FFF",
                        }}
                    >
                        <Typography variant="overline" sx={{ color: "#FFF" }}>
                            Yes,sure
                        </Typography>
                    </Button>
                </Stack>
            )}
        </Dialog>
    );
};

export default LeadCapture;

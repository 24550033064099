import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    getValidSessionItem,
    signJwtAndRedirect,
    userType,
} from "../../util/functions";
import { useStateValue } from "../../util/stateProvider";
import createConfig from "./config";
import iconCreator from "./iconCreator";
import "./topbar.scss";
import NewEstimateModal from "../../views/leads/NewEstimateModal";
import advService from "../../util/advService";
import { LEAD_SPECIFIC_TAB } from "../../util/newconfig";
import { useDispatch, useSelector } from "react-redux";
import { setConfig as setConfigredux } from "../../features/config/configSlice";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";
const profile =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/profile.svg";
const MoreIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/more.svg";
const Logout =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/logout.svg";
const Logo_ShiftRisk =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/host_logos/Logo_ShiftRisk.svg";
const Logo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/brand_blue.svg";

function NewTopbar() {
    const [{ user, uuid, subDomainInfo }, dispatch] = useStateValue();
    const [config, setConfig] = useState();
    const [primary, setPrimary] = useState();
    const [secondary, setSecondary] = useState();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [openNewEstimate, setOpenNewEstimate] = useState(false);
    const location = useLocation();
    const [extraTabs, setExtraTabs] = useState();
    const [toggleExtraTabs, setToggleExtraTabs] = useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    const reducerDispatch = useDispatch();
    const [reportPermission, setReportPermission] = useState({
        d_claim_display: false,
        d_payment_display: false,
        d_report_lead: false,
        d_report_lead_app_usage: false,
        d_report_lead_app_usage_url: "",
        d_report_lead_claims: false,
        d_report_lead_claims_url: "",
        d_report_enrolment: false,
        d_report_enrolment_url: "",
        d_report_lead_email: false,
        d_report_lead_email_url: "",
        d_network_hospital_url: "",
    });
    const { policyDto, homeDto } = useSelector((state) => state.home);

    const expiredPolicyType = getValidSessionItem("expiredPolicy");

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const storedUser = JSON.parse(sessionStorage.getItem("user"));
        const storedLeadString = sessionStorage.getItem("lead");
        const storedLead =
            storedLeadString !== "undefined"
                ? JSON.parse(storedLeadString)
                : undefined;

        try {
            let a = homeDto
                ? Object.keys(homeDto).includes("d_wallet_balance")
                : false;
            setConfig(
                createConfig(storedUser, storedLead, {
                    wallet: homeDto
                        ? Object.keys(homeDto).includes("d_wallet_balance")
                        : false,
                })
            );
        } catch (error) {
            console.error("Error creating config:", error);
        }
    }, [user, uuid, homeDto]);

    const resetPermissions = () => {
        setReportPermission({
            d_claim_display: false,
            d_payment_display: false,
            d_report_lead: false,
            d_report_lead_app_usage: false,
            d_report_lead_app_usage_url: "",
            d_report_lead_claims: false,
            d_report_lead_claims_url: "",
            d_report_enrolment: false,
            d_report_enrolment_url: "",
            d_report_lead_email: false,
            d_report_lead_email_url: "",
            d_network_hospital_url: "",
        });
    };

    useEffect(() => {
        if (uuid) {
            resetPermissions();
            let urlConfig = {
                ...LEAD_SPECIFIC_TAB,
                url: LEAD_SPECIFIC_TAB.url.replace("{uuid}", uuid),
            };

            advService(urlConfig, {}, (res) => {
                if (res?.data.success) {
                    dispatch({
                        type: "set-config",
                        config: res.data.response,
                    });
                    reducerDispatch(setConfigredux(res.data.response));
                    setReportPermission({
                        ...res.data.response,
                        d_report_enrolment:
                            res.data.response?.d_report_enrolment_one_time ||
                            res.data.response?.d_report_enrolment_cont,
                    });
                }
            });
        }
    }, [uuid]);

    const handleScrollToWellness = () => {
        const element = document.getElementById("Healthcare_scroll");
        if (element) {
            element.scrollIntoView();
        }
    };

    const checkPermission = async () => {
        if (user) {
            // allTabs are coming from config which based on user have all tabs
            // let userT = ""
            // if(!policyDto){
            //     userT="USR"
            // }else if{
            //     userT=policyDto?.d_home_policies?.length===0?"USR":
            // }
            let allTabs =
                config[
                    user.userType === "EMPLOYEE"
                        ? policyDto?.d_home_policies?.length > 0
                            ? userType(user)
                            : "USR"
                        : userType(user)
                ].secondary;
            let reportTab = [];
            const tabs = [
                { key: "d_payment_display", name: "Payments" },
                { key: "d_claim_display", name: "Claims" },
                { key: "d_report_lead", name: "Reports" },
                { key: "d_report_lead_claims", name: "Claim Report" },
                { key: "d_report_lead_app_usage", name: "App Usage" },
                { key: "d_report_enrolment", name: "Enrollment" },
                { key: "d_report_lead_email", name: "Email Data" },
            ];

            // removing tabs whose values are false and this is coming from LEAD_SPECIFIC_TAB config call
            tabs.forEach((tab) => {
                if (
                    !reportPermission[tab.key] &&
                    JSON.parse(sessionStorage.getItem("user"))?.userType !==
                        "EMPLOYEE"
                ) {
                    allTabs = allTabs.filter((obj) => obj["name"] !== tab.name);
                }
            });

            // appent urls to report tabs
            allTabs = allTabs.map((obj) => {
                if (obj.name === "Claim Report") {
                    return {
                        ...obj,
                        url: reportPermission?.d_report_lead_claims_url,
                    };
                } else if (obj.name === "App Usage") {
                    return {
                        ...obj,
                        url: reportPermission?.d_report_lead_app_usage_url,
                    };
                } else if (obj.name === "Enrollment") {
                    return {
                        ...obj,
                        url: reportPermission?.d_report_enrolment_url,
                    };
                } else if (obj.name === "Email Data") {
                    return {
                        ...obj,
                        url: reportPermission?.d_report_lead_email_url,
                    };
                }
                return obj;
            });

            // this is just for the mobile view as extratabs are added to the more tabs
            // for desktop screen report tabs are in the 'alltabs' and we're not report tab from this array in mobile view
            if (reportPermission.d_report_lead) {
                reportTab.push(
                    ...allTabs.filter(
                        (obj) =>
                            obj["name"] === "Claim Report" ||
                            obj["name"] === "App Usage"
                    )
                );
            }
            if (reportPermission.d_report_enrolment) {
                reportTab.push(
                    ...allTabs.filter((obj) => obj["name"] === "Enrollment")
                );
            }
            if (reportPermission.d_report_lead_email) {
                reportTab.push(
                    ...allTabs.filter((obj) => obj["name"] === "Email Data")
                );
            }

            const showOnlyTabs = [
                "Members",
                "Payments",
                "Claims",
                "Reports",
                "Claim Report",
                "App Usage",
                "Enrollment",
                "Email Data",
            ];
            if (expiredPolicyType) {
                setSecondary(
                    allTabs.filter((obj) => showOnlyTabs.includes(obj["name"]))
                );
            } else {
                setSecondary(allTabs);
            }

            setExtraTabs(reportTab);
            let tempPrev = null;
            const searchParams = new URLSearchParams(window.location.search);
            tempPrev = JSON.parse(sessionStorage.getItem("user"))?.isMigrated;
            if (tempPrev) {
                setPrimary([]);
            } else {
                setPrimary(config[userType(user)].primary);
            }
        }
    };

    useEffect(() => {
        if (config) {
            checkPermission();
        }
    }, [
        config,
        reportPermission,
        policyDto,
        user?.userType,
        user,
        expiredPolicyType,
    ]);

    const createSecondaryDesktop = (secondary) => {
        if (secondary?.length) {
            let second = secondary?.map((val) => {
                if (
                    !(val.user === "admin" || val.user === "report") ||
                    (expiredPolicyType && val.name === "Claims")
                ) {
                    if (
                        val.name === "Hospitals" &&
                        reportPermission?.d_network_hospital_url &&
                        reportPermission?.d_network_hospital_url !==
                            (null || "null") &&
                        reportPermission?.d_network_hospital_url !== "" &&
                        reportPermission.d_network_hospital_url !== '""'
                    ) {
                        return (
                            // <Link
                            //     to={reportPermission.d_network_hospital_url}
                            //     className={`${
                            //         location.pathname === val.link
                            //             ? "active"
                            //             : ""
                            //     }`}
                            //     target="_blank"
                            //     rel="noopener noreferrer"
                            // >
                            //     <img src={iconCreator(val.icon)} />
                            //     {val.name}
                            // </Link>

                            // create a anchor tag which open in new tab
                            <a
                                href={reportPermission.d_network_hospital_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${
                                    location.pathname === val.link
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <img src={iconCreator(val.icon)} />
                                {val.name === "Wallet" && (
                                    <div className="navChip">
                                        {"₹ " +
                                            homeDto?.d_wallet_balance
                                                .toLocaleString("en-IN")
                                                .toLocaleString("en-IN")}
                                    </div>
                                )}
                                {val.name}
                            </a>
                        );
                    } else {
                        return (
                            <Link
                                to={val.link}
                                onClick={
                                    val.name === "Wallet"
                                        ? handleScrollToWellness
                                        : null
                                }
                                className={`${
                                    location.pathname === val.link
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <img src={iconCreator(val.icon)} />
                                <span
                                    style={{
                                        display: "inline-block",
                                        overflowWrap: "normal",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {val.name === "Wallet" && (
                                        <div className="navChip">
                                            {"₹ " +
                                                homeDto?.d_wallet_balance.toLocaleString(
                                                    "en-IN"
                                                )}
                                        </div>
                                    )}
                                    {val.name}
                                </span>
                            </Link>
                        );
                    }
                }
            });

            let flag = false;
            let temp = (
                <div className="navbar_secondary_dropdown">
                    {secondary?.some(
                        (val) =>
                            val.user === "admin" &&
                            val.name !== "Broker" &&
                            !expiredPolicyType
                    ) && (
                        <div
                            className="navbar_secondary_dropdown_head"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            <img src={iconCreator("admin")} />
                            <div style={{ whiteSpace: "nowrap" }}>
                                Broker{" "}
                                <img
                                    className="secondIcon"
                                    src={iconCreator("arrowD")}
                                />
                            </div>
                        </div>
                    )}
                    <div className="navbar_secondary_dropdown_list">
                        {secondary?.map((val) => {
                            if (
                                val.user === "admin" &&
                                val.user !== "report" &&
                                val.name !== "Broker"
                            ) {
                                flag = true;
                            }
                            return (
                                val.user === "admin" &&
                                val.name !== "Broker" && (
                                    <Link
                                        to={val.link}
                                        className={`${
                                            location.pathname === val.link
                                                ? "active"
                                                : ""
                                        }`}
                                        onClick={
                                            val.name === "Wallet"
                                                ? handleScrollToWellness
                                                : null
                                        }
                                    >
                                        <img src={iconCreator(val.icon)} />
                                        {val.name === "Wallet" && (
                                            <div className="navChip">
                                                {"₹ " +
                                                    homeDto?.d_wallet_balance}
                                            </div>
                                        )}
                                        <span
                                            style={{
                                                display: "inline-block",
                                                overflowWrap: "normal",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {val.name}
                                        </span>
                                    </Link>
                                )
                            );
                        })}
                    </div>
                </div>
            );
            let flag2 = false;
            let temp2 = (
                <div
                    className="navbar_secondary_dropdown"
                    style={{
                        marginLeft: expiredPolicyType ? "-1.7em" : "0",
                    }}
                >
                    {secondary?.some(
                        (val) => val.user === "report" && val.name !== "Reports"
                    ) && (
                        <div
                            className="navbar_secondary_dropdown_head"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            <img src={iconCreator("reports")} />
                            <div style={{ whiteSpace: "nowrap" }}>
                                Reports{" "}
                                <img
                                    className="secondIcon"
                                    src={iconCreator("arrowD")}
                                />
                            </div>
                        </div>
                    )}
                    <div className="navbar_secondary_dropdown_list">
                        {secondary?.map((val) => {
                            if (val.user === "report") {
                                flag2 = true;
                            }

                            return (
                                val.user === "report" &&
                                val.name !== "Reports" && (
                                    <Link
                                        to={val.link}
                                        state={{ url: val.url }}
                                        className={`${
                                            location.pathname === val.link
                                                ? "active"
                                                : ""
                                        }`}
                                        onClick={
                                            val.name === "Wallet"
                                                ? handleScrollToWellness
                                                : null
                                        }
                                    >
                                        <img src={iconCreator(val.icon)} />
                                        {val.name === "Wallet" && (
                                            <div className="navChip">
                                                {"₹ " +
                                                    homeDto?.d_wallet_balance}
                                            </div>
                                        )}{" "}
                                        <span
                                            style={{
                                                display: "inline-block",
                                                overflowWrap: "normal",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {val.name}
                                        </span>
                                    </Link>
                                )
                            );
                        })}
                    </div>
                </div>
            );
            if (flag || flag2) {
                return [...second, flag && temp, flag2 && temp2];
            } else {
                return second;
            }
        }
    };

    const createSecondaryMobile = (currentHomeDto) => {
        if (secondary?.length) {
            let menu = [];
            let length = primary.length ? 3 : 4;
            if (
                user.userType === "EMPLOYEE" &&
                currentHomeDto?.d_home_policies?.length === 0
            ) {
                length = 1;
            }
            for (let i = 0; i < length && secondary[i]; i++) {
                // console.log(secondary, secondary[i], i, "-----------------------ppppp");
                if (
                    secondary[i].name === "Hospitals" &&
                    reportPermission.d_network_hospital_url &&
                    reportPermission.d_network_hospital_url !==
                        (null || "null") &&
                    reportPermission.d_network_hospital_url !== "" &&
                    reportPermission.d_network_hospital_url !== '""'
                ) {
                    menu.push(
                        // create anchor tag which open in new tab
                        <a
                            href={reportPermission.d_network_hospital_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${
                                location.pathname === secondary[i].link
                                    ? "active"
                                    : ""
                            }`}
                            onClick={
                                secondary[i].name === "Wallet"
                                    ? handleScrollToWellness
                                    : null
                            }
                        >
                            <img src={iconCreator(secondary[i].icon)} />{" "}
                            {secondary[i].name === "Wallet" && (
                                <div className="navChip">
                                    {"₹ " +
                                        homeDto?.d_wallet_balance.toLocaleString(
                                            "en-IN"
                                        )}
                                </div>
                            )}{" "}
                            <span
                                style={{
                                    display: "inline-block",
                                    overflowWrap: "normal",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {secondary[i].name}
                            </span>
                        </a>
                    );
                } else {
                    menu.push(
                        <Link
                            to={secondary[i].link}
                            className={`${
                                location.pathname === secondary[i].link
                                    ? "active"
                                    : ""
                            }`}
                            onClick={
                                secondary[i].name === "Wallet"
                                    ? handleScrollToWellness
                                    : null
                            }
                        >
                            <img src={iconCreator(secondary[i].icon)} />{" "}
                            {secondary[i].name === "Wallet" && (
                                <div className="navChip">
                                    {"₹ " +
                                        homeDto?.d_wallet_balance.toLocaleString(
                                            "en-IN"
                                        )}
                                </div>
                            )}
                            <span
                                style={{
                                    display: "inline-block",
                                    overflowWrap: "normal",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {secondary[i].name}
                            </span>
                        </Link>
                    );
                }
            }

            let more = [];
            for (let i = length; i < secondary.length; i++) {
                if (
                    secondary[i].name !== "Broker" &&
                    secondary[i].name !== "Reports"
                ) {
                    more.push(
                        <Link
                            to={secondary[i].link}
                            className={`${
                                location.pathname === secondary[i].link
                                    ? "active"
                                    : ""
                            }`}
                            onClick={
                                secondary[i].name === "Wallet"
                                    ? handleScrollToWellness
                                    : null
                            }
                        >
                            <img src={iconCreator(secondary[i].icon)} />{" "}
                            {secondary[i].name === "Wallet" && (
                                <div className="navChip">
                                    {"₹ " +
                                        homeDto?.d_wallet_balance.toLocaleString(
                                            "en-IN"
                                        )}
                                </div>
                            )}{" "}
                            <span
                                style={{
                                    display: "inline-block",
                                    overflowWrap: "normal",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {secondary[i].name}
                            </span>
                        </Link>
                    );
                }
            }

            if (more.length) {
                menu.push(
                    <div className="navbar_secondary_dropdown_mobile">
                        <div
                            className="navbar_secondary_dropdown_head"
                            onClick={() => {
                                setToggleMenu(2);
                            }}
                        >
                            <img src={profile} />{" "}
                            {userType(user) === "ADMIN" ? "Broker" : "Profile"}{" "}
                            <span />
                        </div>
                        <div
                            className={`navbar_secondary_dropdown_list_wrapper ${
                                toggleMenu === 2 ? "" : "hide"
                            }`}
                            onClick={() => {
                                setToggleMenu(false);
                            }}
                        >
                            <div className="navbar_secondary_dropdown_list_mobile">
                                {more}
                            </div>
                        </div>
                    </div>
                );
            }

            if (primary.length) {
                menu.push(
                    <div className="navbar_secondary_dropdown_mobile">
                        <div
                            className="navbar_secondary_dropdown_head"
                            onClick={() => {
                                setToggleMenu(1);
                            }}
                        >
                            {/* <img src={profile}/> Admin <span/> */}
                            <img src={MoreIcon} /> More <span />
                        </div>
                        <div
                            className={`navbar_secondary_dropdown_list_wrapper ${
                                toggleMenu === 1 ? "" : "hide"
                            }`}
                            onClick={() => {
                                setToggleMenu(false);
                            }}
                        >
                            <div className="navbar_secondary_dropdown_list_mobile">
                                {createMobilePrimary()}
                            </div>
                        </div>
                    </div>
                );
            }
            if (location.pathname !== "/leads") {
                return menu;
            } else {
                return createMobilePrimary();
            }
        }
    };

    const createMobilePrimary = () => {
        let moreMenu = primary?.map((val) =>
            val.link === "/newLead" ? (
                <a
                    onClick={goToNewLead}
                    className={`${
                        location.pathname === val.link ? "active" : "pointer"
                    }`}
                >
                    <img src={iconCreator(val.icon)} />
                    <span
                        style={{
                            display: "inline-block",
                            overflowWrap: "normal",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {val.name}
                    </span>
                    {val.name === "Wallet" && (
                        <div className="navChip">
                            {"₹ " +
                                homeDto?.d_wallet_balance.toLocaleString(
                                    "en-IN"
                                )}
                        </div>
                    )}
                </a>
            ) : (
                <Link
                    to={val.link}
                    className={`${
                        location.pathname === val.link ? "active" : ""
                    }`}
                    onClick={
                        val.name === "Wallet" ? handleScrollToWellness : null
                    }
                >
                    <img src={iconCreator(val.icon)} />{" "}
                    <span
                        style={{
                            display: "inline-block",
                            overflowWrap: "normal",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {val.name}
                    </span>
                    {val.name === "Wallet" && (
                        <div className="navChip">
                            {"₹ " +
                                homeDto?.d_wallet_balance.toLocaleString(
                                    "en-IN"
                                )}
                        </div>
                    )}
                </Link>
            )
        );
        if (extraTabs.length > 0) {
            console.log("extratabs", extraTabs);
            moreMenu.push(
                ...extraTabs.map((val) =>
                    val.name !== "Reports" ? (
                        <Link
                            to={val.link}
                            state={{ url: val.url }}
                            className={`${
                                location.pathname === val.link ? "active" : ""
                            }`}
                            onClick={
                                val.name === "Wallet"
                                    ? handleScrollToWellness
                                    : null
                            }
                        >
                            <img src={iconCreator(val.icon)} />{" "}
                            <span
                                style={{
                                    display: "inline-block",
                                    overflowWrap: "normal",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {val.name}
                            </span>
                            {val.name === "Wallet" && (
                                <div className="navChip">
                                    {"₹ " +
                                        homeDto?.d_wallet_balance.toLocaleString(
                                            "en-IN"
                                        )}
                                </div>
                            )}
                        </Link>
                    ) : null
                )
            );
        }

        return moreMenu;
    };

    const goToNewLead = () => {
        setOpenNewEstimate(true);

        // signJwtAndRedirect("/newLead");

        // window.location.href = window.location.origin + '/newLead'
    };

    if (window.location.pathname === "/whitelistedHospitals") {
        return <></>;
    }

    return (
        <>
            <div className="navbar">
                {/* desktop  screen */}
                {width >= 900 && (
                    <div className="navbar_wrapper desktop">
                        <div className="navbar_primary">
                            <div
                                className={`navbar_primary_logo ${
                                    window.location.hostname ===
                                        "kmddastur.imt.care" ||
                                    window.location.hostname ===
                                        "kmdastur.imt.care" ||
                                    window.location.hostname ===
                                        "insurance.kmdastur.com" ||
                                    window.location.hostname ===
                                        "kmddemo.imt.care"
                                        ? "increasedHeight"
                                        : ""
                                }`}
                            >
                                <img src={subDomainInfo?.siteLogo} />
                            </div>
                            {primary?.length
                                ? primary?.map((val) =>
                                      val.link === "/newLead" ? (
                                          <a
                                              onClick={goToNewLead}
                                              className={`${
                                                  location.pathname === val.link
                                                      ? "active"
                                                      : "pointer"
                                              }`}
                                              style={{
                                                  display: "inline-block",
                                                  overflowWrap: "normal",
                                                  whiteSpace: "nowrap",
                                              }}
                                          >
                                              {val.name}
                                          </a>
                                      ) : (
                                          <Link
                                              to={val.link}
                                              className={`${
                                                  location.pathname === val.link
                                                      ? "active"
                                                      : ""
                                              }`}
                                              style={{
                                                  display: "inline-block",
                                                  overflowWrap: "normal",
                                                  whiteSpace: "nowrap",
                                              }}
                                          >
                                              {val.name}
                                          </Link>
                                      )
                                  )
                                : createSecondaryDesktop(secondary)}
                            {homeDto?.d_language && (
                                <div
                                    style={{
                                        marginBottom: "1em",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <GoogleTranslate />
                                </div>
                            )}
                            {!expiredPolicyType && (
                                <Link
                                    to="/logout"
                                    className="logout topbar_logout"
                                    style={{
                                        marginLeft: homeDto?.d_language
                                            ? "0"
                                            : "auto",
                                    }}
                                >
                                    <img src={Logout} /> Logout
                                </Link>
                            )}
                        </div>
                        {!!primary?.length &&
                            !primary?.some(
                                (val) =>
                                    val.link.split("/")[1] ===
                                    location.pathname.split("/")[1]
                            ) && (
                                <div className="navbar_secondary">
                                    {JSON.parse(sessionStorage.getItem("lead"))
                                        ?.companyName && (
                                        <div
                                            className={`navbar_secondary_companyName ${
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "lead"
                                                    )
                                                )?.companyName.length > 11
                                                    ? "long"
                                                    : "short"
                                            }`}
                                        >
                                            <div>
                                                {
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            "lead"
                                                        )
                                                    )?.companyName
                                                }
                                            </div>
                                            <span></span>
                                        </div>
                                        // <span></span>
                                        // </div>
                                    )}
                                    {createSecondaryDesktop(secondary)}
                                </div>
                            )}
                    </div>
                )}

                {/* mobile screen */}
                {width < 900 && (
                    <div className="navbar_wrapper mobile">
                        <div className="navbar_primary">
                            <Link to="/" className="navbar_primary_logo">
                                <img src={subDomainInfo?.siteLogo} />
                            </Link>
                            {homeDto?.d_language && (
                                <div
                                    style={{
                                        marginBottom: "1em",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <GoogleTranslate />
                                </div>
                            )}
                            {!expiredPolicyType && (
                                <Link
                                    to="/logout"
                                    className="logout topbar_logout"
                                    style={{
                                        marginLeft: homeDto?.d_language
                                            ? "0"
                                            : "auto",
                                    }}
                                >
                                    <img src={Logout} /> Logout
                                </Link>
                            )}
                        </div>
                        <div className="navbar_secondary_mobileWrapper">
                            <div className="navbar_secondary">
                                {createSecondaryMobile(policyDto)}
                            </div>
                        </div>
                    </div>
                )}

                {openNewEstimate && (
                    <NewEstimateModal close={() => setOpenNewEstimate(false)} />
                )}
            </div>
            {JSON.parse(sessionStorage.getItem("user"))?.isMigrated &&
                (!(
                    JSON.parse(sessionStorage.getItem("user"))
                        ?.actualUserType === "EMPLOYEE"
                ) ||
                    !JSON.parse(sessionStorage.getItem("user"))?.isEmployee) &&
                sessionStorage.getItem("uuid") && (
                    <div
                        style={{
                            background: "#FCF9E0",
                        }}
                        id="navbar_banner"
                        
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                maxWidth: "1440px",
                                margin: "0 auto",
                                flexDirection: `${
                                    width < 900 ? "column" : "row"
                                }`,
                                alignItems: "center",
                                padding: `8px  ${
                                    width < 900 ? "0px" : "125px"
                                }`,
                                color: "#474747",
                            }}
                        >
                            <span>
                                {sessionStorage.getItem("companyName") ||
                                    JSON.parse(sessionStorage.getItem("lead"))
                                        ?.companyName}
                            </span>
                            <span>
                                UUID:&nbsp;
                                {sessionStorage.getItem("uuid")}
                            </span>
                        </div>
                    </div>
                )}
        </>
    );
}

export default NewTopbar;

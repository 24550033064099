import { Button, CircularProgress, Dialog, DialogTitle } from "@mui/material";
import { createBrowserHistory } from "history";
import { lazy, Suspense, useEffect, useState, useLayoutEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import CustomAlert from "./components/CustomAlert/CustomAlert";
import service from "./util/axiosConfig";
import { BASE_URL, HOME, HOME_EMPLOYEE, INTIALISE } from "./util/config";
import {
    checkSessionExpiration,
    getValidSessionItem,
    signJwtAndRedirect,
} from "./util/functions";
import { useStateValue } from "./util/stateProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WrapperRoute from "./routes/WrapperRoute";
import { ThemeProvider } from "@mui/material/styles";
import AuthorizedRoute from "./components/AuthorizedRoute";
import muiTheme from "./util/muiTheme";
import NotFound from "./components/NotFound";
import { AES } from "crypto-js";
import { useNavigate } from "react-router";
import advService from "./util/advService";
import { AGENT_LEAD_LOGOS_GET, USER_TYPE_CHANGE } from "./util/newconfig";

import Logout from "./views/UserAuthentication/Logout/Logout";
import Google from "./views/google";
import Microsoft from "./views/Microsoft/Microsoft";
import Redirect from "./views/Redirect/Redirect";
import Unsubscribe from "./views/Unsubscribe/Unsubscribe";
import FileInput from "./components/Input/FileInput";
import TestView from "./views/TestView/TestView";
import Okta from "./views/Okta/Okta";
import UploadHomeV1 from "./views/v1/Claims/YourClaims/AddClaims/UploadDocuments/UploadHome";
import UploadHome from "./views/Claims/YourClaims/AddClaims/UploadDocuments/UploadHome";
import { getSubDomainInfo } from "./util/function/getSubDomainInfo";
import CareStatus from "./views/CareHealthInsurance/CareCards/CareStatus";
import checkResponseAndSetStep from "./util/function/checkResponse";
import { useDispatch, useSelector } from "react-redux";
import { setSignIn } from "./util/function";
import { redirect } from "./util/function/redirect";
import {
    setHomeDtoV2,
    setHomeDtoV2Loading,
    setPolicyDto,
} from "./features/home/homeSlice";
import { updateAuthStore } from "./features/auth/authSlice";
import Kmd from "./views/Kmd/Kmd";
import KmdAuthentication from "./views/Kmd/Authentication/kmdAuthentication";
import useTheme from "./util/muiTheme";
import KmdPaymentComplete from "./views/Kmd/KmdPaymentComplete";
import ReactGA4 from "react-ga4";
import BenefitsPage from "./views/Benefits/BenefitsPage";
import BenefitView from "./views/Benefits/BenefitView";
import Faqs from "./views/Benefits/Faq";
import { useQuery } from "react-query";
import { policyService } from "./services/api/policyServics";
import NewEachPolicy from "./views/PolicyDetails/NewEachPolicy";
import { homeService } from "./services/api/homeService";
import NewPolicyPage from "./views/PolicyDetails/NewPolicyPage";
import WhatsNotCovered from "./views/PolicyDetails/WhatsNotCovered";
import NewEditMember from "./views/PolicyDetails/NewEditMember";
import DownloadCenterV2 from "./views/DownloadCenter/DownloadCenterV2";
import ScrollToTop from "./util/function/scrollToTop";
import EnrollmentStep1 from "./views/Enrollment/Steps/EnrollmentStep1V2";
import UpstoxRedirect from "./views/upstoxRedirect/UpstoxRedirect";
import { userService } from "./services/api/userService/userService";
import GenericModalV2 from "./components/Modals/GenericModalV2";
import VerificationView from "./components/PhoneVerification/VerificationView";
import ShowEmployeeView from "./views/ShowEmployeeView";
import OrderDetails from "./views/HealthCheckup/Steps/OrderDetails";
import LeadCapture from "./components/leadCapture/LeadCapture";

const KmdCarePayment = lazy(() => import("./views/Kmd/kmdCarePayment"));

const Params = lazy(() => import("./views/Params/Params"));
const MyPlans = lazy(() => import("./views/Plans/MyPlans"));
const AdminClaim = lazy(() => import("./views/Claims/AdminClaims/AdminClaim"));
const AddClaimsV1 = lazy(() =>
    import("./views/v1/Claims/YourClaims/AddClaims/AddClaims")
);
const AddClaims = lazy(() =>
    import("./views/Claims/YourClaims/AddClaims/AddClaims")
);
const ViewClaim = lazy(() => import("./views/Claims/ViewClaim/ViewClaim"));
const Authentication = lazy(() =>
    import("./views/UserAuthentication/Authentication.tsx")
);
const CarePaid = lazy(() => import("./views/CareHealthInsurance/CarePaid"));
const Enrollment = lazy(() => import("./views/Enrollment/Enrollment"));
// const NewEnrollment = lazy(() => import("./views/Enrollment/NewEnrollment"));
const NewEnrollment = lazy(() => import("./views/Enrollment/NewEnrollmentV2"));
const HospitalList = lazy(() => import("./views/HospitalList/HospitalList"));

const New = lazy(() => import("./views/CareHealthInsurance/new/Home"));
const AddMasterPolicy = lazy(() =>
    import("./views/AddMasterPolicy/AddMasterPolicy")
);

// const theme = muiTheme;
const AuthRoute = lazy(() => import("./views/AuthRoute/AuthRoute"));
const SignIn = lazy(() => import("./views/UserAuthentication/SignIn/SignIn"));
const ForgotPassword = lazy(() =>
    import("./views/UserAuthentication/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
    import("./views/UserAuthentication/ResetPassword/ResetPassword")
);
const VerifyLink = lazy(() =>
    import("./views/UserAuthentication/VerifyLink/VerifyLink")
);
const Features = lazy(() => import("./views/Features/Features"));
const EmployeeData = lazy(() => import("./views/Table/EmployeeData"));
const Leads = lazy(() => import("./views/leads/Leads"));
const Hospitals = lazy(() => import("./views/Hopitalization/Hospitals"));
const Details = lazy(() => import("./views/Details/Details"));
const Endorsement = lazy(() => import("./views/Endorsement/Endorsement"));
const Admin = lazy(() => import("./views/Admin/Admin"));
const CreateUsers = lazy(() => import("./views/Admin/CreateUsers/CreateUsers"));
const Payments = lazy(() => import("./views/Admin/Payments/Payments"));
const Downloadcenter = lazy(() =>
    import("./views/DownloadCenter/DownloadCenter")
);
const Home = lazy(() => import("./views/Home/Home"));
const ActivatePolicy = lazy(() =>
    import("./views/ActivatePolicy/ActivatePolicy.jsx")
);
const PolicyDetails = lazy(() =>
    import("./views/PolicyDetails/PolicyDetails.jsx")
);
// const Claims = lazy(() => import("./views/Claims/Claims"));
const Claims = lazy(() => import("./views/Claims/ClaimsV2"));
const ClaimsV1 = lazy(() => import("./views/v1/Claims/Claims"));
const CliamTimeline = lazy(() => import("./views/Claims/ClaimTimeline"));
const UploadDocumentsV1 = lazy(() =>
    import(
        "./views/v1/Claims/YourClaims/AddClaims/UploadDocuments/UploadDocuments"
    )
);
const UploadDocuments = lazy(() =>
    import(
        "./views/Claims/YourClaims/AddClaims/UploadDocuments/UploadDocuments"
    )
);
const UploadDeficiencyV1 = lazy(() =>
    import(
        "./views/v1/Claims/YourClaims/AddClaims/UploadDocuments/UploadDeficiency"
    )
);
const UploadDeficiency = lazy(() =>
    import(
        "./views/Claims/YourClaims/AddClaims/UploadDocuments/UploadDeficiency"
    )
);
const Agents = lazy(() => import("./views/Agents/Agents"));
const AgentUsers = lazy(() => import("./views/AgentUsers/AgentUsers"));
const Insurance = lazy(() => import("./views/Insurance/Insurance"));
const VerifyPhone = lazy(() => import("./views/VerifyPhone/VerifyPhone"));
const ClaimsIntimation = lazy(() =>
    import("./views/ClaimsIntimation/ClaimsIntimation")
);
const PostIntimation = lazy(() =>
    import("./views/PostIntimation/PostIntimation")
);
const MasterPolicy = lazy(() => import("./views/MasterPolicy/MasterPolicy"));
const CareHealthInsurance = lazy(() =>
    import("./views/CareHealthInsurance/CareHealthInsurance")
);
const CareHealthInsuranceV1 = lazy(() =>
    import("./views/v1/CareHealthInsurance/CareHealthInsurance")
);
const ClaimsReport = lazy(() =>
    import("./views/Reports/ClaimsReport/ClaimsReport")
);
const AppUsageReport = lazy(() =>
    import("./views/Reports/AppUsageReport/AppUsageReport")
);

const EnrollmentReport = lazy(() =>
    import("./views/Reports/EnrollmentReport/EnrollmentReport")
);

const EmailDataReport = lazy(() =>
    import("./views/Reports/EmailDataReport/EmailDataReport")
);

const ProfilePage = lazy(() => import("./views/Profile/Profile"));
const EditProfile = lazy(() => import("./views/Profile/EditProfile"));

const HealthCheckup = lazy(() => import("./views/HealthCheckup/HealthCheckup"));

const Sso = lazy(() => import("./views/UserAuthentication/SSO/Sso"));

const App = () => {
    const [{ Authorization, subDomainInfo, user, uuid, config }, dispatch] =
        useStateValue();
    const [shouldScrollCare, setShouldScrollCare] = useState(false);
    let [{ alertStatus }] = useStateValue();
    const [loading, setLoading] = useState(false);
    const history = createBrowserHistory();
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const reducerDispatch = useDispatch();
    const { policyDto } = useSelector((state) => state.home);
    const theme = useTheme();

    const expiredPolicyType = getValidSessionItem("expiredPolicy");
    const expiredPolicyPid = getValidSessionItem("expiredPid");

    const [isUpdateAvailable, setUpdateAvailable] = useState(false);
    const [currentV, setCurrentVersion] = useState("");

    const { openVerificationModal, openLeadCaptureModal } = useSelector(
        (state) => state.home
    );
    const [iphoneUpdateUrl, setIphoneUpdateUrl] = useState(
        "https://www.apple.com/in/app-store/"
    );
    const { data: policyData, isSuccess } = useQuery(
        ["policyData", user],
        () => policyService.employee.getPolicyData(user),
        {
            enabled: !!user && user.userType.toLowerCase() === "employee",
            onSuccess: (data) => {
                reducerDispatch(setPolicyDto(data));
            },
        }
    );
    useQuery(
        ["homeDataV2", user],
        () => {
            reducerDispatch(setHomeDtoV2Loading(true));
            return homeService.getHomeDataV2();
        },
        {
            staleTime: 60000,
            enabled: !!user && user.userType.toLowerCase() === "employee",
            onSuccess: (data) => {
                reducerDispatch(setHomeDtoV2Loading(false));
                reducerDispatch(setHomeDtoV2(data));
            },
        }
    );

    function appStoreURL() {
        const hostname = window.location.hostname;
        switch (hostname) {
            case "app.imt.care":
                return "https://apps.apple.com/in/app/imt-care/id6451188201";
            case "employeebenefit.goinsureindia.com":
                return "https://apps.apple.com/in/app/go-insure/id6503248338?platform=iphone";
            default:
                return "https://apps.apple.com/in/app/imt-care/id6451188201";
        }
    }

    function checkIphoneVersion() {
        const userAgent = window.navigator.userAgent;
        const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        const currentVersion = ios ? userAgent.split("_")[1] : null;
        return currentVersion;
    }

    useEffect(() => {
        const latestVersion = "1.31"; // Change this version to the latest version of the app
        setCurrentVersion(latestVersion);
        const currentVersion = checkIphoneVersion();
        setCurrentVersion(currentVersion || "");
        if (currentVersion && currentVersion !== latestVersion) {
            setUpdateAvailable(true);
        }
        setIphoneUpdateUrl(appStoreURL());
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (checkSessionExpiration()) {
                setOpen(true);
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    useLayoutEffect(() => {
        if (window.location.origin === "https://app.insuremyteam.com") {
            if (localStorage.getItem("Authorization")) {
                let url = AES.encrypt(
                    localStorage.getItem("Authorization"),
                    "Secret"
                );
                localStorage.clear("Authorization");
                window.location.href = `https://app.imt.care/redirect/${encodeURIComponent(
                    url
                )}`;
            } else {
                window.location.href = `https://app.imt.care${
                    window.location.pathname || ""
                }`;
            }
        }
    }, []);

    useLayoutEffect(() => {
        let uuid = sessionStorage.getItem("uuid");
        (async () => {
            if (user) {
                if (user?.userType.toLowerCase() === "user") {
                    reducerDispatch(setPolicyDto({ d_home_policies: [] }));
                } else if (user?.userType.toLowerCase() === "employee") {
                    // const res = await service.get(
                    //     `${BASE_URL}v1/policy/employee${
                    //         user.multipleLeads ? "/" + uuid : ""
                    //     }`
                    // );
                    // if (res.data.success) {
                    //     reducerDispatch(setPolicyDto(res.data.response));
                    // } else {
                    //     reducerDispatch(setPolicyDto({ d_home_policies: [] }));
                    // }
                    // if (isSuccess) {
                    //     reducerDispatch(setPolicyDto(policyData));
                    // } else {
                    //     reducerDispatch(setPolicyDto({ d_home_policies: [] }));
                    // }
                } else if (uuid) {
                    let url = expiredPolicyType
                        ? `${BASE_URL}v1/policy/expired/${uuid}`
                        : `${BASE_URL}v1/policy/${uuid}`;
                    const res = await service.get(url);
                    if (res.data.success) {
                        reducerDispatch(setPolicyDto(res.data.response));
                    }
                }
            }
        })();
    }, [user?.userType, uuid, Authorization]);

    useLayoutEffect(() => {
        setLoading(true);
        getSubDomainInfo().then((res) => {
            if (res) {
                dispatch({
                    type: "set-subDomainInfo",
                    subDomainInfo: res,
                });

                let existingFavicon =
                    document.querySelector("link[rel*='icon']");

                if (existingFavicon) {
                    existingFavicon.parentNode.removeChild(existingFavicon);
                }
                const favicon = document.createElement("link");
                favicon.rel = "icon";
                favicon.type = "image/png";
                favicon.href = res?.titleBar.favicon;
                document.head.appendChild(favicon);
                let count = 0;
                document.title = res?.titleBar?.text;
                if (res?.theme?.primary && res?.theme?.filter_primary) {
                    const variables = {
                        "--primary": res?.theme?.primary,
                        "--filter_primary": res?.theme?.filter_primary,
                        "--lead_table_button":
                            res?.theme?.lead_table_button ||
                            res?.theme?.primary,
                        "--checkbox":
                            res?.theme?.checkbox || res?.theme?.primary,
                    };

                    Object.entries(variables).forEach(([key, value]) => {
                        document.documentElement.style.setProperty(key, value);
                    });
                }
            }

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (uuid) {
            const config = {
                ...AGENT_LEAD_LOGOS_GET,
                url: AGENT_LEAD_LOGOS_GET.url.replace("{uuid}", uuid),
            };
            advService(config, {}, (res) => {
                if (res?.data.success) {
                    console.log("Agent Lead Logos", res.data);
                    dispatch({
                        type: "set-leadInfo",
                        leadInfo: res.data.response,
                    });
                }
            });
        } else {
            dispatch({
                type: "set-leadInfo",
                leadInfo: null,
            });
        }
    }, [uuid]);

    useEffect(() => {
        setLoading(true);

        let user = undefined;
        try {
            if (sessionStorage.getItem("user")) {
                user = JSON.parse(sessionStorage.getItem("user"));
            }
        } catch (e) {
            console.log("error");
        }

        if (window.location.pathname.split("/")?.[1] === "authorized") {
            setLoading(false);
            return;
        }

        if (window.location.pathname === "/showEmployeeView") {
            setLoading(false);
            return;
        }
        initiate(user);
        setLoading(false);
    }, [dispatch, alertStatus, Authorization]);

    useEffect(() => {
        if (user) {
            if (subDomainInfo?.thirdPartyServices?.yellowMessenger) {
                let chatbot = false;
                let innitializer = setInterval(() => {
                    if (chatbot) {
                        window.YellowMessengerPlugin.init({
                            payload: {
                                user: user,
                                token: localStorage.getItem("Authorization"),
                            },
                        });
                        window.YellowMessengerPlugin.show();
                        document.getElementById(
                            "ymPluginDivContainerInitial"
                        ).style.visibility = "visible";
                        clearInterval(innitializer);
                    } else {
                        chatbot = !!window.YellowMessengerPlugin;
                    }
                }, 1000);
                return () => clearInterval(innitializer);
            }
        }
    }, [user, subDomainInfo]);

    useEffect(() => {
        setInterval(() => {
            if (
                localStorage.getItem("Authorization") &&
                !sessionStorage.getItem("Authorization")
            ) {
                userService
                    .initiateWithHeader(localStorage.getItem("Authorization"))
                    .then((res) => {
                        if (res.data.success) {
                            console.log(
                                "Authorization updated1",
                                res.headers.authorization
                            );
                            dispatch({
                                type: "set-authorization",
                                data: res.headers.authorization,
                            });
                            sessionStorage.setItem(
                                "user",
                                JSON.stringify({
                                    ...res.data.response.user,
                                    isEmployee:
                                        JSON.parse(
                                            sessionStorage.getItem("user") ||
                                                "{}"
                                        )?.isEmployee ||
                                        res.data.response?.user?.isEmployee,
                                    actualUserType:
                                        JSON.parse(
                                            sessionStorage.getItem("user") ||
                                                "{}"
                                        )?.actualUserType ||
                                        res.data.response?.user?.userType,
                                    userType:
                                        JSON.parse(
                                            sessionStorage.getItem("user") ||
                                                "{}"
                                        )?.userType ||
                                        res.data.response?.user?.userType,
                                })
                            );
                        }
                    });
            }
        }, 1000 * 60 * 5);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("Authorization");
        sessionStorage.removeItem("uuid");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("leads");
        sessionStorage.removeItem("lead");
        localStorage.removeItem("loginTime");
        localStorage.removeItem("subDomainInfo");
        setOpen(false);
    };

    const initiate = (user = null) => {
        if (window.location.pathname !== "/google") {
            if (!user) {
                service
                    .get(INTIALISE)
                    .then(async (res) => {
                        console.log(
                            "Authorization updated 11",
                            res.headers.authorization
                        );

                        if (res.data.success) {
                            if (res.data.response.user) {
                                let newRes = checkResponseAndSetStep(
                                    res,
                                    reducerDispatch
                                );

                                if (newRes) {
                                    let response = newRes.data.response;
                                    let tempUuid = null;
                                    // if (
                                    //     window.location.pathname === "/members"
                                    // ) {
                                    const searchParams = new URLSearchParams(
                                        window.location.search
                                    );
                                    tempUuid = searchParams.get("id");
                                    // }
                                    if (tempUuid) {
                                        sessionStorage.setItem(
                                            "redirect",
                                            "admin"
                                        );

                                        const searchParams =
                                            new URLSearchParams(
                                                window.location.search
                                            );
                                        const companyName =
                                            searchParams.get("companyName");
                                        if (companyName) {
                                            sessionStorage.setItem(
                                                "companyName",
                                                decodeURIComponent(companyName)
                                            );
                                        }
                                        sessionStorage.setItem(
                                            "agentName",
                                            searchParams.get("agentName")
                                        );
                                        sessionStorage.setItem(
                                            "expiredPolicy",
                                            searchParams.get("expiredPolicy")
                                        );
                                        sessionStorage.setItem(
                                            "expiredPid",
                                            searchParams.get("expiredPid")
                                        );
                                    }
                                    setSignIn(
                                        newRes.headers.authorization,
                                        response?.user || null,
                                        response.lead || null,
                                        response.page === "leadView"
                                            ? tempUuid || null
                                            : response.uuid ||
                                                  response.user?.uuid ||
                                                  null,
                                        response?.d_verify_phone,
                                        dispatch
                                    );
                                    const userProperties = {
                                        userType: response?.user.userType,
                                        userName: response?.user.personName,
                                        userId: response?.user.userId,
                                    };

                                    ReactGA4.set({
                                        user_properties: userProperties,
                                    });

                                    redirect(newRes, navigate);
                                }
                            } else {
                                // if (
                                //     user.isMigrated &&
                                //     sessionStorage.getItem("redirect") !==
                                //         "admin" &&
                                //     window.location.origin !==
                                //         "http://localhost:3000"
                                // ) {
                                //     window.location.pathname = "/crm";
                                // }
                                console.log(
                                    "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb"
                                );

                                if (
                                    window.location.href.includes(
                                        "resetPassword"
                                    ) ||
                                    window.location.href.includes(
                                        "magicLogin"
                                    ) ||
                                    window.location.href.includes(
                                        "unsubscribe"
                                    ) ||
                                    window.location.href.includes(
                                        "carePaymentComplete"
                                    ) ||
                                    window.location.href.includes(
                                        "login/company"
                                    )
                                ) {
                                } else {
                                    if (
                                        window.location.pathname !== "/login" &&
                                        window.location.pathname !== "/" &&
                                        window.location.pathname !== "/logout"
                                    ) {
                                        sessionStorage.setItem(
                                            "requestedSite",
                                            window.location.pathname
                                        );
                                    }
                                    if (
                                        window.location.href.includes(
                                            "architect"
                                        )
                                    ) {
                                        navigate("/architect");
                                    } else {
                                        navigate("/login");
                                    }
                                }
                            }
                        } else {
                            if (window.location.href.includes("architect")) {
                                navigate("/architect");
                            } else {
                                navigate("/login");
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                const u = JSON.parse(sessionStorage.getItem("user"));

                const userProperties = {
                    userType: u.userType,
                    userName: u.personName,
                    userId: u.userId,
                };

                ReactGA4.set({ user_properties: userProperties });

                const searchParams = new URLSearchParams(
                    window.location.search
                );
                let tempUuid = searchParams.get("id");

                if (tempUuid) {
                    sessionStorage.setItem(
                        "companyName",
                        searchParams.get("companyName")
                    );
                    sessionStorage.setItem(
                        "agentName",
                        searchParams.get("agentName")
                    );
                }

                if (u) {
                    dispatch({
                        type: "set-user",
                        payload: u,
                    });
                    reducerDispatch(updateAuthStore({ user: u }));
                }
                if (sessionStorage.getItem("uuid") || tempUuid) {
                    dispatch({
                        type: "set-uuid",
                        uuid: sessionStorage.getItem("uuid") || tempUuid,
                    });
                }

                if (
                    (window.location.pathname === "/" ||
                        window.location.pathname === "/login" ||
                        window.location.pathname.includes("resetPassword") ||
                        window.location.pathname === "/architect" ||
                        window.location.pathname.split("/")[2] === "company") &&
                    JSON.parse(sessionStorage.getItem("verifyPhone")) === false
                ) {
                    // setTimeout(() => {
                    if (u.userType === "EMPLOYEE") {
                        navigate("/home");
                    } else {
                        if (u.multipleLeads) {
                            window.location.href =
                                window.location.origin + "/leads";
                        } else {
                            window.location.href =
                                window.location.origin + "/home";
                            // })
                        }
                    }
                    // }, 1000);
                }
            }
        }
    };

    return loading ? (
        <div>Loading...</div>
    ) : (
        <ThemeProvider theme={theme}>
            <Suspense
                fallback={
                    <div className="loading-container">
                        <CircularProgress
                            color={
                                subDomainInfo?.theme?.primary
                                    ? "primary"
                                    : "neutral"
                            }
                        />
                    </div>
                }
            >
                <ScrollToTop />
                <GenericModalV2 open={openVerificationModal}>
                    <VerificationView />
                </GenericModalV2>
                {openLeadCaptureModal.open && <LeadCapture />}
                <Routes>
                    <Route
                        exact
                        path="/authorized"
                        element={
                            window.location.href.includes("sessionStorage") ? (
                                <UpstoxRedirect />
                            ) : (
                                <Sso />
                            )
                        }
                    />

                    <Route
                        exact
                        path="/showEmployeeView"
                        element=<ShowEmployeeView />
                    />

                    <Route exact path="/auth/:token" element={<AuthRoute />} />
                    <Route
                        exact
                        path="/architect"
                        element={<KmdAuthentication />}
                    />
                    <Route
                        exact
                        path="/login"
                        element={<Authentication initialStep="signIn" />}
                    />
                    <Route
                        exact
                        path="/login/onBoard"
                        element={<Authentication initialStep="onBoard" />}
                    />
                    <Route exact path="/signin" element={<Authentication />} />
                    <Route
                        exact
                        path="/login/auto"
                        element={<Authentication autoLoad={true} />}
                    />
                    <Route
                        exact
                        path="/forgotPassword"
                        element={<ForgotPassword />}
                    />
                    <Route
                        exact
                        path="/resetPassword/:uid"
                        element={<ResetPassword />}
                    />
                    <Route
                        exact
                        path="/magicLogin/:uid"
                        element={<VerifyLink />}
                    />
                    <Route exact path="/" element={<Authentication />} />
                    <Route
                        exact
                        path="/login/company/:uuid"
                        element={
                            <Authentication
                                initialStep={"companySpecificSignIn"}
                            />
                        }
                    />
                    <Route exact path="/oldAuth" element={<SignIn />} />
                    <Route
                        exact
                        path="/verifyPhone"
                        element={<VerifyPhone />}
                    />
                    <Route
                        path="/unsubscribe/:refId"
                        element={<Unsubscribe />}
                    />
                    <Route path="/new" element={<New />} />
                    <Route
                        path="/google"
                        element={<Google autoLoad={true} />}
                    />
                    <Route
                        path="/carePaymentComplete/:proposalNumber"
                        element={<CarePaid />}
                    />
                    <Route
                        path="/redirect/:token"
                        element={<Redirect initiate={initiate} />}
                    />
                    <Route path="/cacheCheck" element={<>First cache</>} />

                    <Route element={<WrapperRoute />}>
                        <Route
                            exact
                            path="/members"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <EmployeeData />
                                </AuthorizedRoute>
                            }
                        />

                        <Route
                            exact
                            path="/customisePlan/:policyType"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "agent", "broker"]}
                                >
                                    <MyPlans />
                                </AuthorizedRoute>
                            }
                        />

                        <Route exact path="/logout" element={<Logout />} />
                        <Route
                            exact
                            path="/leads"
                            element={
                                <AuthorizedRoute
                                    migrate={true}
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employer",
                                        "broker",
                                    ]}
                                >
                                    <Leads />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/hospitals"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Hospitals />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/whitelistedHospitals"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Hospitals unauth={true} />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/details"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "broker", "agent"]}
                                >
                                    <Details />
                                </AuthorizedRoute>
                            }
                        />

                        <Route
                            exact
                            path="/endorsement"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "agent", "broker"]}
                                >
                                    <Endorsement />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/admin"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "agent", "broker"]}
                                >
                                    <Admin />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/activate"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "agent", "broker"]}
                                >
                                    <ActivatePolicy />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/features"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Features />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/plans"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                    ]}
                                    notFoundText="No Active Policies"
                                >
                                    {user?.userType === "EMPLOYEE" ? (
                                        <NewPolicyPage />
                                    ) : (
                                        <PolicyDetails />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/plans/:uuid/:pid"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                    ]}
                                    notFoundText="No Active Policies"
                                >
                                    <NewEachPolicy />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <CreateUsers />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/payments"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                            0 && config?.d_payment_display
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Payments />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/downloadcenter"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                        "user",
                                    ]}
                                >
                                    {JSON.parse(
                                        sessionStorage.getItem("user") || "{}"
                                    )?.userType === "EMPLOYEE" ? (
                                        <DownloadCenterV2 />
                                    ) : (
                                        <Downloadcenter />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/home"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                        "user",
                                    ]}
                                >
                                    <Home initiate={initiate} />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/agents"
                            element={
                                <AuthorizedRoute
                                    migrate={true}
                                    authorized={["admin", "broker"]}
                                >
                                    <Agents />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/agents/users"
                            element={
                                <AuthorizedRoute
                                    migrate={true}
                                    authorized={["admin", "broker"]}
                                >
                                    <AgentUsers />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/insurers"
                            element={
                                <AuthorizedRoute
                                    migrate={true}
                                    authorized={["admin", "broker"]}
                                >
                                    <Insurance />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/claimsIntimation"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <ClaimsIntimation />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/postIntimation"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <PostIntimation />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                            0 && config?.d_claim_display
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                    notFoundText="No Claims Found"
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <Claims />
                                    ) : (
                                        <ClaimsV1 />
                                    )}
                                    {/* <Claims /> */}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/newClaim"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <AddClaims />
                                    ) : (
                                        <AddClaimsV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claim/TimeLine/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <CliamTimeline />
                                    ) : (
                                        <CliamTimeline />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/viewClaim/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <ViewClaim />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/upload"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <UploadHome />
                                    ) : (
                                        <UploadHomeV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/uploadDeficiency/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <UploadDeficiency />
                                    ) : (
                                        <UploadDeficiencyV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/uploadDocuments"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <UploadDocuments />
                                    ) : (
                                        <UploadDocumentsV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/uploadDocuments/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <UploadDocuments />
                                    ) : (
                                        <UploadDocumentsV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/claims/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                    notFoundText="No Claims Found"
                                >
                                    <AdminClaim />
                                </AuthorizedRoute>
                            }
                        />
                        {/* <Route
                            path="/masterPolicy"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "broker"]}
                                >
                                    <MasterPolicy />
                                </AuthorizedRoute>
                            }
                        /> */}
                        {/* <Route
                            path="/masterPolicy/addMasterPolicy"
                            element={
                                <AuthorizedRoute
                                    authorized={["admin", "broker"]}
                                >
                                    <AddMasterPolicy />
                                </AuthorizedRoute>
                            }
                        /> */}
                        <Route
                            path="/careHealthInsurance"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                        "user",
                                    ]}
                                >
                                    {JSON.parse(sessionStorage.getItem("user"))
                                        ?.userType === "EMPLOYEE" ? (
                                        <CareHealthInsurance />
                                    ) : (
                                        <CareHealthInsuranceV1 />
                                    )}
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/carePaymentProcessing/:proposalNumber/:payStatus"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <CareStatus />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/carePaymentComplete/"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <CarePaid />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/params"
                            element={
                                <AuthorizedRoute
                                    authorized={
                                        !user?.isMigrated
                                            ? ["admin", "agent", "broker"]
                                            : []
                                    }
                                >
                                    <Params />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/kmd/:policyType/:masterUuid"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "broker",
                                        "employee",
                                    ]}
                                >
                                    <Kmd />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/kmd/architect/payment/response"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "broker",
                                        "employee",
                                    ]}
                                >
                                    <KmdPaymentComplete />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/params/:agentId"
                            element={
                                <AuthorizedRoute
                                    authorized={
                                        !user?.isMigrated
                                            ? ["admin", "agent", "broker"]
                                            : []
                                    }
                                >
                                    <Params />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/claim-report"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <ClaimsReport />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/app-usage-report"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <AppUsageReport />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/enrollment-report"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <EnrollmentReport />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/emaildata-report"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <EmailDataReport />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/enrollment"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Enrollment />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/enrollmentHome"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <EnrollmentStep1 />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/newEnrollment"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <NewEnrollment />
                                </AuthorizedRoute>
                            }
                        />

                        <Route
                            exact
                            path="/testCarePayment/:proposalNumber"
                            element={
                                <AuthorizedRoute authorized={["admin"]}>
                                    <KmdCarePayment />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/newAuth"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <Authentication />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/hospitalList"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <HospitalList />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/benefits"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                    ]}
                                >
                                    <BenefitsPage />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/benefits/:id"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                    ]}
                                >
                                    <BenefitView />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/benefits/faqs"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        "employee",
                                        "employer",
                                        "broker",
                                    ]}
                                >
                                    <Faqs />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/plans/whatsNotCovered"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <WhatsNotCovered />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/plans/newEditMember"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "agent",
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employer"]
                                            : []),
                                        "broker",
                                    ]}
                                >
                                    <NewEditMember />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <AuthorizedRoute authorized={["employee"]}>
                                    <ProfilePage />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="/edit-profile"
                            element={
                                <AuthorizedRoute authorized={["employee"]}>
                                    <EditProfile />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/healthCheckup/:provider"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        ...(policyDto?.d_home_policies?.length >
                                        0
                                            ? ["employee"]
                                            : []),
                                    ]}
                                >
                                    <HealthCheckup />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/healthCheckup/:provider/client/:uuid"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "broker",
                                        "agent",
                                        ["admin", "broker", "agent"].includes(
                                            user?.actualUserType?.toLowerCase()
                                        )
                                            ? "employee"
                                            : "",
                                    ]}
                                >
                                    <HealthCheckup />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            exact
                            path="/healthCheckupDetails/orderDetails/:uuid"
                            element={
                                <AuthorizedRoute
                                    authorized={[
                                        "admin",
                                        "broker",
                                        "agent",
                                        "employee",
                                    ]}
                                >
                                    <OrderDetails />
                                </AuthorizedRoute>
                            }
                        />
                    </Route>

                    <Route path="/microsoft" element={<Microsoft />} />
                    <Route path="/okta/:token" element={<Okta />} />
                    <Route
                        path="/TestView"
                        element={
                            <AuthorizedRoute
                                authorized={[
                                    "admin",
                                    "agent",
                                    ...(policyDto?.d_home_policies?.length > 0
                                        ? ["employee"]
                                        : []),
                                    ...(policyDto?.d_home_policies?.length > 0
                                        ? ["employer"]
                                        : []),
                                    "broker",
                                ]}
                            >
                                <TestView />
                            </AuthorizedRoute>
                        }
                    />

                    <Route path="*" element={<NotFound status={404} />} />
                </Routes>
                <CustomAlert />
            </Suspense>
            {/* </Router > */}
            <Dialog open={open} onClose={handleLogout}>
                <DialogTitle>
                    "You have been logged out due to inactivity. Please log in
                    again."
                </DialogTitle>
                <Button onClick={handleLogout}>OK</Button>
            </Dialog>
            <ToastContainer />
            {/* {isUpdateAvailable && (
                <Dialog
                    open={isUpdateAvailable}
                    fullWidth
                    maxWidth="xs"
                    disableEscapeKeyDown
                >
                    <DialogTitle>Update available</DialogTitle>
                    <DialogContent sx={{ pb: 0 }}>
                        <Typography variant="body2">
                            A new version of the app is available. Please update
                            to continue.
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 2 }}>
                        <a href={iphoneUpdateUrl}>
                            <button className="buttonPrimary pr20 pl20">
                                Update Now
                            </button>
                        </a>
                    </DialogActions>
                </Dialog>
            )} */}
        </ThemeProvider>
    );
};

export default App;

import { useNavigate, useParams } from "react-router-dom";
import Iconify from "../../../components/iconify";
import V2Container from "../../../components/Layout/V2Container";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { Divider as VerticalDivider } from "antd";
import { healthCheckupService } from "../../../services/api/healthCheckup/healthCheckup";
import { useQuery, useQueryClient } from "react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setHealthCheckupBooking,
    setHealthCheckupOrderDetails,
} from "../../../features/healthCheckup/healthCheckupSlice";
import { formatTime, getWeekday } from "../../../util/functions";
import { CANCEL_BOOKING } from "../../../util/newconfig";
import advService from "../../../util/advService";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import { useStateValue } from "../../../util/stateProvider";
import useFlow from "../../../services/hooks/useFlow";
import GenericModalContainer from "../../../components/Modals/GenericModalContainer";

const OrderDetails = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [{ user }, dispatch] = useStateValue();
    const { flow } = useFlow();

    const reducerDispatch = useDispatch();
    const { data, isLoading, isSuccess } = useQuery(
        ["order", uuid],
        () => healthCheckupService?.booking?.details(uuid as string),
        {
            staleTime: 1000 * 60 * 5,
            retry: 1,
        }
    );
    const [cancelDialog, setCancelDialog] = useState("");

    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const handleClose = () => {
        setCancelDialog("");
    };

    useEffect(() => {
        if (isSuccess) {
            reducerDispatch(setHealthCheckupOrderDetails(data));
        }
    }, [isSuccess, data, reducerDispatch]);

    const cancelOrder = async (uuid: string) => {
        handleClose();
        const config = {
            ...CANCEL_BOOKING,
            url: CANCEL_BOOKING.url.replace("{uuid}", uuid),
        };

        advService(config, {}, (res: AxiosResponse<JavaResponse<any>>) => {});
        queryClient.invalidateQueries(["homeDataV2"]);

        navigate(-1);
    };

    useLayoutEffect(() => {
        if (flow === "Client" || flow === "Guest") {
            dispatch({
                type: "set-userType",
                userType: "EMPLOYEE",
            });
        }
    }, [flow]);

    return (
        <V2Container
            heading="Order details"
            sx={{
                gap: "24px",
            }}
        >
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            flex: "1 0 0",
                            alignSelf: "stretch",
                            borderRadius: "24px",
                            border: "1px solid #EAEAEF",
                            overflow: "hidden",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                padding: "16px",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                gap: "16px",
                                alignSelf: "stretch",
                            }}
                        >
                            <Stack
                                flexDirection={"column"}
                                gap={"16px"}
                                alignItems={"flex-start"}
                                alignSelf={"stretch"}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            padding: "8px 16px",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "16px",
                                            background: (theme) =>
                                                theme?.palette?.info?.lighter,
                                        }}
                                    >
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                color: (theme) =>
                                                    theme?.palette?.info
                                                        ?.darker,
                                            }}
                                        >
                                            {data?.slotDate?.split("-")[2]}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: (theme) =>
                                                    theme?.palette?.info
                                                        ?.darker,
                                            }}
                                        >
                                            {getWeekday(data?.slotDate)}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        Order ID: {data?.bookingId}
                                    </Typography>
                                </Stack>
                                <Stack
                                    flexDirection={"column"}
                                    gap={"16px"}
                                    width={"100%"}
                                    alignItems={"flex-start"}
                                    alignSelf={"stretch"}
                                >
                                    <Stack
                                        flexDirection={"row"}
                                        gap={"8px"}
                                        alignItems={"center"}
                                        alignSelf={"stretch"}
                                    >
                                        <Iconify
                                            icon={
                                                "material-symbols:pace-rounded"
                                            }
                                            sx={{
                                                width: "28px",
                                                height: "28px",
                                                padding: "6px",
                                            }}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: (theme) =>
                                                    theme?.palette?.text
                                                        ?.primary,
                                            }}
                                        >
                                            {`${formatTime(
                                                data?.slotStartTime
                                            )} - ${formatTime(
                                                data?.slotEndTime
                                            )}`}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        flexDirection={"row"}
                                        gap={"8px"}
                                        alignItems={"center"}
                                        alignSelf={"stretch"}
                                    >
                                        <Iconify
                                            icon={
                                                "material-symbols:location-on-outline-rounded"
                                            }
                                            sx={{
                                                width: "28px",
                                                height: "28px",
                                                padding: "6px",
                                            }}
                                        />
                                        <Typography
                                            variant="body3"
                                            sx={{
                                                color: (theme) =>
                                                    theme?.palette?.text
                                                        ?.primary,
                                                width: "100%",
                                            }}
                                        >
                                            {`${data?.address?.addressLine1}, ${data?.address?.addressLine2}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.pincode}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider variant="dashed" />
                            <Stack
                                flexDirection={"row"}
                                alignItems={"flex-start"}
                                alignSelf={"stretch"}
                                justifyContent={"space-between"}
                            >
                                <Typography
                                    variant="overline"
                                    sx={{
                                        color: "red",
                                        cursor: "pointer",
                                        textAlign: "center",
                                    }}
                                    onClick={() =>
                                        setCancelDialog(
                                            data?.bookingId as string
                                        )
                                    }
                                >
                                    Cancel Order
                                </Typography>
                                {/* <VerticalDivider
                                    variant="dashed"
                                    type="vertical"
                                    style={{ height: "100%" }}
                                />
                                <Typography
                                    variant="overline"
                                    sx={{
                                        color: (theme) =>
                                            theme?.palette?.grey[800],
                                        cursor: "pointer",
                                        textAlign: "center",
                                    }}
                                >
                                    Modify/Reschedule
                                </Typography> */}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                padding: "16px",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "8px",
                                alignSelf: "stretch",
                                background: "#F8F7FA",
                            }}
                        >
                            <Chip
                                size="small"
                                label={
                                    <Typography
                                        margin={0}
                                        variant="label"
                                        color={(theme) =>
                                            theme?.palette?.info?.dark
                                        }
                                    >
                                        {data?.status}
                                    </Typography>
                                }
                                sx={{
                                    width: "fit-content",
                                    background: (theme) =>
                                        theme?.palette?.info?.lighter,
                                }}
                            />
                            <Typography
                                variant="label"
                                sx={{
                                    color: (theme) =>
                                        theme?.palette?.text?.primary,
                                }}
                            >
                                Order is scheduled. A lab technician will be
                                assigned to you.
                            </Typography>
                        </Box>
                        {/* <Box
                            sx={{
                                display: "flex",
                                padding: "16px",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "8px",
                                alignSelf: "stretch",
                                background: "#F8F7FA",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "16px",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    gap: "8px",
                                    alignSelf: "stretch",
                                    background: "#FFF",
                                    borderRadius: "16px",
                                    boxShadow:
                                        "0px 2px 6px 0px rgba(39, 57, 147, 0.06)",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",

                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "37px",
                                        backgroundColor: (theme) =>
                                            theme?.palette?.error?.lighter,
                                    }}
                                >
                                    <Box
                                        component={"img"}
                                        src={
                                            "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Daughter.png"
                                        }
                                        sx={{
                                            width: "48px",
                                            height: "48px",
                                            padding: "8px",
                                        }}
                                    />
                                </Box>
                                <Stack
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    alignItems={"flex-start"}
                                >
                                    <Typography
                                        variant="mobileLabel"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.secondary,
                                        }}
                                    >
                                        Lab Technician
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        Nimish Patel
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        +91 9876543210
                                    </Typography>
                                </Stack>
                                <div
                                    style={{
                                        flex: 1,
                                    }}
                                />
                                <IconButton
                                    sx={{
                                        width: "36px",
                                        height: "36px",
                                        padding: "8px",
                                    }}
                                >
                                    <Iconify
                                        icon={"mdi:phone-outline"}
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box> */}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            width: "395px",
                            padding: "16px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "32px",
                        }}
                    >
                        <Stack
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                            alignSelf={"stretch"}
                            gap={"24px"}
                        >
                            <Typography variant="subtitle1">
                                Test details
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "20px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                    borderRadius: "24px",
                                    border: "1px solid #EAEAEF",
                                }}
                            >
                                {data?.packageDetails?.map((item, index) => (
                                    <>
                                        <Stack
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Stack flexDirection={"column"}>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        color: (theme) =>
                                                            theme?.palette?.text
                                                                ?.primary,
                                                    }}
                                                >
                                                    {item?.label}
                                                </Typography>
                                                <Typography
                                                    variant="body3"
                                                    sx={{
                                                        color: (theme) =>
                                                            theme?.palette?.text
                                                                ?.secondary,
                                                    }}
                                                >
                                                    {`${item?.tag} member added`}
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: (theme) =>
                                                        theme?.palette?.text
                                                            ?.primary,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "Roboto",
                                                    }}
                                                >
                                                    ₹
                                                </span>
                                                {item?.value}
                                            </Typography>
                                        </Stack>
                                        {index <
                                            data.packageDetails.length - 1 && (
                                            <Divider variant="dashed" />
                                        )}
                                    </>
                                ))}
                            </Box>
                        </Stack>
                        <Stack
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                            alignSelf={"stretch"}
                            gap={"24px"}
                        >
                            <Typography variant="subtitle1">
                                Billing details
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "20px",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    gap: "8px",
                                    alignSelf: "stretch",
                                    borderRadius: "24px",
                                    border: "1px solid #EAEAEF",
                                }}
                            >
                                <Iconify
                                    icon={"material-symbols:payments-outline"}
                                    sx={{
                                        width: "36px",
                                        height: "36px",
                                        padding: "8px",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        borderRadius: "500px",
                                        border: "1px solid rgba(255,255,255,0.00)",
                                        background: "#F8F7FA",
                                    }}
                                />
                                <Stack
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    alignItems={"flex-start"}
                                    width={"100%"}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        Cash on delivery
                                    </Typography>
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.secondary,
                                        }}
                                    >
                                        Amount to be paid at the time of
                                        collection either through CASH or UPI
                                    </Typography>
                                </Stack>
                                <div style={{ flex: 1 }} />
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: (theme) =>
                                            theme?.palette?.text?.primary,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Roboto",
                                        }}
                                    >
                                        ₹
                                    </span>
                                    {data?.totalAmount}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "20px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                    borderRadius: "24px",
                                    border: "1px solid #EAEAEF",
                                }}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    width={"100%"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        Item Total
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        <span style={{ fontFamily: "Roboto" }}>
                                            ₹
                                        </span>
                                        {data?.totalAmount}
                                    </Typography>
                                </Stack>
                                <Divider variant="dashed" />
                                <Stack
                                    flexDirection={"row"}
                                    width={"100%"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        Total
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: (theme) =>
                                                theme?.palette?.text?.primary,
                                        }}
                                    >
                                        <span style={{ fontFamily: "Roboto" }}>
                                            ₹
                                        </span>
                                        {data?.totalAmount}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </>
            )}
            <GenericModalContainer
                open={cancelDialog !== ""}
                headingText="Cancel Order"
                subHeadingText={""}
                styles={{ minHeight: "0px", minWidth: "35%" }}
                headerStyle={{ marginBottom: 0 }}
                close={handleClose}
            >
                <Typography variant="body1">
                    Are you sure you want to cancel this order?
                </Typography>
                <Stack
                    direction={"row"}
                    height={40}
                    justifyContent={"flex-end"}
                >
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => cancelOrder(cancelDialog)}
                    >
                        Cancel Order
                    </Button>
                    <Button onClick={handleClose}>No</Button>
                </Stack>
            </GenericModalContainer>
        </V2Container>
    );
};

export default OrderDetails;

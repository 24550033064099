import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import HomeDto, { HomeDtoV2 } from "../../types/DTO/homeDTO";

interface HomeState {
    homeDto: HomeDto | null;
    policyDto: any;
    homeDtoV2: HomeDtoV2 | null;
    homeDtoV2Loading: boolean;
    homeBannerBackground: string;
    openVerificationModal: boolean;
    customerTemplateId: string;
    openLeadCaptureModal: {
        open: boolean;
        type: string;
        text: string;
        id: string;
    };
}

const initialState: HomeState = {
    homeDto: null,
    policyDto: null,
    homeDtoV2: null,
    homeDtoV2Loading: false,
    homeBannerBackground: "white",
    openVerificationModal: false,
    customerTemplateId: "",
    openLeadCaptureModal: {
        open: false,
        type: "",
        text: "",
        id: "",
    },
};

// create slice
const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setHomeDto: (
            state: HomeState,
            action: PayloadAction<HomeState["homeDto"]>
        ) => {
            state.homeDto = action.payload;
        },
        setPolicyDto: (
            state: HomeState,
            action: PayloadAction<HomeState["policyDto"]>
        ) => {
            state.policyDto = action.payload;
        },
        setHomeDtoV2: (
            state: HomeState,
            action: PayloadAction<HomeState["homeDtoV2"]>
        ) => {
            state.homeDtoV2 = action.payload;
        },
        setHomeDtoV2Loading: (
            state: HomeState,
            action: PayloadAction<HomeState["homeDtoV2Loading"]>
        ) => {
            state.homeDtoV2Loading = action.payload;
        },
        setHomeBannerBackground: (
            state: HomeState,
            action: PayloadAction<HomeState["homeBannerBackground"]>
        ) => {
            state.homeBannerBackground = action.payload;
        },
        setOpenVerificationModal: (
            state: HomeState,
            action: PayloadAction<HomeState["openVerificationModal"]>
        ) => {
            state.openVerificationModal = action.payload;
        },
        setCustomerTemplateId: (
            state: HomeState,
            action: PayloadAction<HomeState["customerTemplateId"]>
        ) => {
            state.customerTemplateId = action.payload;
        },
        setOpenLeadCaptureModal: (
            state: HomeState,
            action: PayloadAction<HomeState["openLeadCaptureModal"]>
        ) => {
            state.openLeadCaptureModal = action.payload;
        },
    },
});

export const {
    setHomeDto,
    setPolicyDto,
    setHomeDtoV2,
    setHomeDtoV2Loading,
    setHomeBannerBackground,
    setOpenVerificationModal,
    setCustomerTemplateId,
    setOpenLeadCaptureModal,
} = homeSlice.actions;

export default homeSlice.reducer;

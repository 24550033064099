// import jwt from "jsonwebtoken";
import { BASE_URL, GROUPHEALTH, JWT_SECRET } from "./config";
import CryptoJS from "crypto-js";
import advService from "./advService";
import { ADDONS_REDIRECT_GET, GET_BUTTON_ACTION } from "./newconfig";
import { checkIphone } from "./function/checkIphone";
import openIphoneLink from "./function/openIphoneLink";
import copyFunction from "./function/copyFunction";
import alert from "./alerts";
import service from "./axiosConfig";
import {
    setCustomerTemplateId,
    setOpenLeadCaptureModal,
    setOpenVerificationModal,
} from "../features/home/homeSlice";
const CopyIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/general/copy.svg";

export const signJwtAndRedirect = (route) => {
    let user = sessionStorage.getItem("user");
    if (typeof user === "string") {
        user = JSON.parse(user);
    }

    const payload = {
        Authorization: localStorage.getItem("Authorization"),
        user: user,
        uuid: sessionStorage.getItem("uuid"),
        route: route,
        expire: Date.now() + 2 * 60 * 1000,
    };

    console.log();

    let token = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        JWT_SECRET
    ).toString();

    let enCodedToken = "";
    enCodedToken = encodeURIComponent(token);
    enCodedToken = encodeURIComponent(enCodedToken);
    console.log("ujjwal", enCodedToken);
    if (enCodedToken) {
        window.location.href = GROUPHEALTH + "/auth/" + enCodedToken;
    }

    // jwt.sign(payload, JWT_SECRET, { expiresIn: 120 }, (err, token) => {
    //   console.log(token);
    //   window.location.href = `${GROUPHEALTH}/auth/${token}`;
    // });
};

export function getWeekday(dateString) {
    const date = new Date(dateString);
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return weekdays[date.getUTCDay()];
}

export const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = mins < 10 ? `0${mins}` : mins;
    return `${formattedHours}:${formattedMinutes} ${period}`;
};

export const captureEvent = (event) => {
    service.get(`${BASE_URL}v1/employee/activity/${event}`).then((res) => {
        console.log(res);
    });
};

export const formatValue = (value, empty = false) => {
    if (!value) {
        return "-";
    }
    if (value === 0 && empty) {
        return "";
    }
    return new Intl.NumberFormat("en-IN").format(
        Math.round(value).toFixed(0).toLocaleString("en-IN")
    );
};
export const handleResponseAlert = (message, type, duration = 2000, alertObj) =>
    alertObj
        ? alert(message, type, duration, alertObj)
        : alert(message, type, duration);

export const checkSessionExpiration = () => {
    if (
        JSON.parse(sessionStorage.getItem("subDomainInfo"))?.idealSessionTimeout
    ) {
        let loginTime = JSON.parse(localStorage.getItem("loginTime"));
        loginTime = new Date(loginTime);
        let currentTime = new Date();
        let timeDiff = Math.abs(currentTime - loginTime);
        let seconds = Math.floor(timeDiff / 1000);
        if (
            seconds >
            JSON.parse(sessionStorage.getItem("subDomainInfo"))
                .idealSessionTimeout
        ) {
            return true;
        }
    }
    return false;
};

export const getBarValue = (key) => {
    if (sessionStorage.getItem(key)) {
        return sessionStorage.getItem(key);
    } else {
        return localStorage.getItem(key);
    }
};

export const transform = (value) => {
    value = parseInt(value);
    if (value < 10) {
        return `0${value}`;
    }
    return value;
};

// Convert UTC date into YYYY-MM-DD format
export const convertToYYYY = (date) => {
    return `${date.getFullYear()}-${transform(date.getMonth() + 1)}-${transform(
        date.getDate()
    )}`;
};

export const confirmLead = (templateId) => {
    service
        .get(`${BASE_URL}v1/employee/lead/confirmation/${templateId}`)
        .then((res) => {
            console.log(res);
        });
};

const handleButtonClick = async (
    templateId,
    navigate,
    dispatch,
    setLoading,
    callback
) => {
    if (setLoading) {
        setLoading(true);
    }
    let config = {
        ...GET_BUTTON_ACTION,
        url: GET_BUTTON_ACTION.url.replace("{templateId}", templateId),
    };
    advService(config, {}, (res) => {
        if (res.data.success) {
            const { label, value, d_verify_phone, tag } = res.data.response;
            if (d_verify_phone) {
                dispatch(setCustomerTemplateId(templateId));
                dispatch(setOpenVerificationModal(true));
                if (setLoading) {
                    setLoading(false);
                }
                return;
            }
            if (label === "internal") {
                if (value) {
                    if (value === "benefits") {
                        // window.location.href = `/${value}/${templateId}`;
                        navigate(`/${value}/${templateId}`);
                    } else if (value.includes("plans")) {
                        let fromParam = "home";
                        if (value.includes("networkHospital")) {
                            fromParam += "&template=networkHospital";
                            captureEvent("HOSPITAL");
                        }
                        navigate(`/${value}&from=${fromParam}`);
                        // window.location.href = `/${value}&from=home`;
                    } else if (value === "lead") {
                        confirmLead(templateId);
                        dispatch(
                            setOpenLeadCaptureModal({
                                open: true,
                                type: "lead",
                                text: tag,
                                id: templateId,
                            })
                        );
                    } else if (value === "leadConfirm") {
                        dispatch(
                            setOpenLeadCaptureModal({
                                open: true,
                                type: "leadConfirm",
                                text: tag,
                                id: templateId,
                            })
                        );
                    } else {
                        navigate(`/${value}`);
                        // window.location.href = `/${value}`;
                    }
                }
            } else if (label === "external") {
                if (value) {
                    checkIphone()
                        ? openIphoneLink(value)
                        : window.open(value, "_blank");
                    // window.open(value, "_blank");
                }
            } else {
                console.error("Unknown intent:", label);
            }
            if (callback) {
                callback();
            }
        }
        if (setLoading) setLoading(false);
    });
};

export default handleButtonClick;

export const convertToDD = (date) => {
    if (date === null) {
        return "-";
    }
    if (date.length > 10) {
        console.log(date, "/================");
    }
    date = date.slice(0, 10);
    if (!date) {
        return "-";
    }
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let NewDate = date.split("-").reverse();
    NewDate[1] = months[parseInt(NewDate[1]) - 1];
    return NewDate.join("-");
    // }
};

export const handleMMM = (date, givenDateFormat) => {
    console.log(date, givenDateFormat, "date, givenDateFormat");
    let dateArray = date?.split(/[-/]/);
    let joinBy = date?.includes("-") ? "-" : "/";
    let monthIndex = givenDateFormat
        .split(/[-/]/)
        .findIndex((each) => each?.toLowerCase()?.includes("mmm"));
    if (monthIndex !== -1 && dateArray[monthIndex]) {
        dateArray[monthIndex] = dateArray[monthIndex]?.toLowerCase();
        dateArray[monthIndex] =
            dateArray[monthIndex][0]?.toUpperCase() +
            dateArray[monthIndex]?.slice(1);
        return dateArray?.join(joinBy);
    }
    return date;
};

export const userType = (user) => {
    let userT = "Default";
    if (user) {
        switch (user?.userType) {
            case "ADMIN":
            case "BROKER":
                userT = "ADMIN";
                break;
            case "AGENT":
                userT = "AGENT";
                break;
            case "CUSTOMER":
            case "CUST":
                userT = "CUSTOMER";
                break;
            case "EMPLOYER":
                userT = "EMPLOYER";
                break;
            case "EMPLOYEE":
                userT = "USER";
                break;
            case "CUSTOM":
                userT = "CUSTOM";
                break;
            default:
                break;
        }
    }
    return userT;
};

export const generateRandomString = (length = 6, numOnly) => {
    let result = "";
    const characters = numOnly
        ? "0123456789"
        : "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return result;
};

export const getTrimmedText = (text, size) => {
    if (text?.length > size) {
        return text.substring(0, size) + "...";
    }
    return text;
};

export const getDateDelta = (date1, date2) => {
    if (date1 && date2) {
        const date1Milli = new Date(date1).getTime();
        const date2Milli = new Date(date2).getTime();
        const daysDelta = parseInt(
            (date1Milli - date2Milli) / (1000 * 60 * 60 * 24)
        );
        return daysDelta;
    } else {
        return 0;
    }
};

export function calculateDaysUntilExpiration(endDate) {
    const currentDate = new Date().getTime();
    const expirationDate = new Date(endDate).getTime();

    const diffInMs = expirationDate - currentDate;

    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return diffInDays;
}
export const underAgeValidate = (birthday) => {
    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    // var optimizedBirthday = birthday.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(birthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

    if (myAge < 18) {
        return false;
    } else {
        return true;
    }
};

export const convertToYYYYMMDD = (date) => {
    // Here date is in format "04-Dec-2000"
    // Converting to format YYYY-MM-DD which is default format required by input[type="date"]
    date = date.split("-");
    let month = "";
    switch (date[1]) {
        case "Jan":
            month = "01";
            break;
        case "Feb":
            month = "02";
            break;
        case "Mar":
            month = "03";
            break;
        case "Apr":
            month = "04";
            break;
        case "May":
            month = "05";
            break;
        case "Jun":
            month = "06";
            break;
        case "Jul":
            month = "07";
            break;
        case "Aug":
            month = "08";
            break;
        case "Sep":
            month = "09";
            break;
        case "Oct":
            month = "10";
            break;
        case "Nov":
            month = "11";
            break;
        case "Dec":
            month = "12";
            break;
        default:
            break;
    }
    const val = `${date[2]}-${month}-${date[0]}`;
    return val;
};
export const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === "object") {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

export const convertToDDMMYYYY = (date) => {
    const newDate = date.split("-");
    let month = "";
    switch (newDate[1]) {
        case "01":
            month = "Jan";
            break;
        case "02":
            month = "Feb";
            break;
        case "03":
            month = "Mar";
            break;
        case "04":
            month = "Apr";
            break;
        case "05":
            month = "May";
            break;
        case "06":
            month = "Jun";
            break;
        case "07":
            month = "Jul";
            break;
        case "08":
            month = "Aug";
            break;
        case "09":
            month = "Sep";
            break;
        case "10":
            month = "Oct";
            break;
        case "11":
            month = "Nov";
            break;
        case "12":
            month = "Dec";
            break;
        default:
            break;
    }
    return `${newDate[2]}-${month}-${newDate[0]}`;
};

export const trimString = (str, length) =>
    str?.length > length ? str.substring(0, length) + "..." : str;

// convert camel case to title case
export const camel2title = (camelCase) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();

// replaces {val} in string to it's val
export const stringParser = (str, keys) => {
    const generateElement = (data) => {
        let config = keys[data];
        console.log("testGenerator", config);
        switch (config?.action) {
            case "copy":
                return (
                    <>
                        <b className="copy">{config.display}</b>
                        <img
                            src={CopyIcon}
                            alt="copy"
                            style={{
                                cursor: "pointer",
                                height: "auto",
                                width: "12px",
                                paddingLeft: "2px",
                            }}
                            onClick={() => {
                                copyFunction(config.display);
                            }}
                        />
                    </>
                );
            case "redirect":
                return (
                    <span
                        className="hyperLink"
                        onClick={() => handleLink(config.url)}
                    >
                        {config.display}
                    </span>
                );
            case "backend":
                return <div className="api">{data}</div>;
            default:
                break;
        }
    };

    const handleLink = (url) => {
        console.log("testGenerator", url);
        let urlConfig = { ...ADDONS_REDIRECT_GET, url: url };
        advService(urlConfig, {}, (res) => {
            if (res.data.success) {
                checkIphone()
                    ? openIphoneLink(res.data.response.url)
                    : window.open(res.data.response.url, "_blank");
            }
        });
    };

    let list = [];
    let temp = "";
    let flag = false;

    for (let i = 0; i < str.length; i++) {
        if (str[i] == "{") {
            list.push(temp);
            list.push(<>&nbsp;</>);
            temp = "";
            flag = true;
            continue;
        }
        if (str[i] == "}") {
            list.push(generateElement(temp));
            list.push(<>&nbsp;</>);
            temp = "";
            // console.log("stringParser", generateElement(temp))
            flag = false;
            continue;
        }

        temp += str[i];
    }
    list.push(temp);

    return list;
};

export const dateDifference = (date1, date2) => {
    // Get the difference in milliseconds between the two dates
    var diff = date2 - date1;

    // Convert the difference to days
    var diffInDays = diff / (1000 * 60 * 60 * 24);

    // Convert the difference to years
    var diffInYears = diffInDays / 365;

    // Return the difference in days and years
    return [diffInDays, diffInYears];
};

export function getValidSessionItem(key) {
    const item = sessionStorage.getItem(key);
    return item === "null" || item === null ? null : item;
}

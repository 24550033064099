export const msalConfig = (domain) => ({
    auth: {
        clientId: "4141334b-0a39-433e-b5f3-d9c74e1d237b",
        redirectUri: `https://${domain}/login`,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};

// // Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};

// // https://app.insuremyteam.com/auth/microsoft/secure/add#code=M.R3_BAY.cfb81a95-1941-5294-b5c7-c537767eb4ce&client_info=eyJ2ZXIiOiIxLjAiLCJzdWIiOiJBQUFBQUFBQUFBQUFBQUFBQUFBQUFNelZfTWQ5S2plOHlCSzJ6Y0FKS2lVIiwibmFtZSI6IlN1bWFuIFNhdXJhdiIsInByZWZlcnJlZF91c2VybmFtZSI6InN1bWFuMjUwMjk5QG91dGxvb2suY29tIiwib2lkIjoiMDAwMDAwMDAtMDAwMC0wMDAwLTU2NTgtM2IxMWY3ZjQyMmVkIiwidGlkIjoiOTE4ODA0MGQtNmM2Ny00YzViLWIxMTItMzZhMzA0YjY2ZGFkIiwiaG9tZV9vaWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtNTY1OC0zYjExZjdmNDIyZWQiLCJ1aWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtNTY1OC0zYjExZjdmNDIyZWQiLCJ1dGlkIjoiOTE4ODA0MGQtNmM2Ny00YzViLWIxMTItMzZhMzA0YjY2ZGFkIn0&state=eyJpZCI6IjAyY2I2ZTI0LTIxOWMtNGVlNy1hMDMxLTMzYWY3NjNkZGMxZiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicG9wdXAifX0%3d
